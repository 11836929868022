<template>
    <div>
        <!-- boton cargar inventario -->
        <button
            type="button"
            class="vs-component vs-button vs-button-primary vs-button-relief rounded-full"
            @click="toggleRoute()"
        >
            <feather-icon icon="PlusIcon" class="inline-block align-middle"></feather-icon>
            Cargar Preorden
        </button>

        <!-- boton Filtro de busqueda -->
        <!-- <button
            type="button"
            class="vs-component vs-button vs-button-relief rounded-full ml-5"
            style="background-color: #FFF; color: #CCC;"
            @click="toggleFilter()"
        >
            <feather-icon icon="SlidersIcon" class="inline-block align-middle transform rotate-90"></feather-icon>&nbsp;
            Filtros de Búsqueda
        </button> -->

        <!-- Formulario de busqueda -->
        <!-- <div
            class="w-1/4 inline-flex align-middle rounded-full float-right pt-2 pl-2"
            style="background-color: #CCC; color: #666;"
        >
            <feather-icon icon="SearchIcon" class="inline-block align-middle" @click="focusSearchInput()"></feather-icon>&nbsp;
            <input
                id="search-icon"
                type="text"
                class="w-11/12 inline-block align-middle bg-transparent border-0 mb-2"
                placeholder="¿Qué estas buscando?"
                autofocus
            />
        </div> -->

        <!-- filtros de busqueda -->
        <filters class="mt-10" v-if="showFilters"></filters>

        <router-view class="mt-10"></router-view>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

//import filters from './Filters.vue';

export default Vue.extend({
    data() {
        return {
            showFilters: false,
            showLoad: false,
        }
    },

    watch: {
        showFilters( val ) {
            if( !val ) {
                this.$vs.loading();
                this.$store.dispatch( 'inventories/getAllInventories' )
                    .then( () => this.$vs.loading.close() );
            }
        }
    },

    created() {
        this.$store.dispatch( 'updatePageTitle' , {
            title: 'Preorden',
            subtitle: 'Pre ordenes por usuario',
        });
    },

    methods: {
        focusSearchInput() {
            //document.getElementById('search-icon').focus();
        },
        
        toggleFilter() {
            this.showFilters = !this.showFilters

            if( this.showFilters ) {
                this.showLoad = false;
            }
        },

        // toogle route come load inventory and inventory list
        toggleRoute() {
            const path = this.$router.currentRoute.path;
            if( path === '/app/preorders/load' ) this.$router.push( '/app/preorders' );
            else this.$router.push( '/app/preorders/load' );
            
        },
    },

    computed: {
        stores() {
            return this.$store.state.users.stores;
        },
    },

    components: {
        //filters,
    }
})
</script>